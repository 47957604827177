import {
  defaultLocale,
  getFederalStateValues,
  getPossibleBirthDateValues,
  dateFormatByCountry,
  checkTaxIdForCountry,
} from '@bemer/middleware';
import {
  ICountriesToLanguages,
  IPropsBemModuleOrderABemerTrial,
  IPropsUiLibBase,
} from '@bemer/types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BemBlockContent } from '../../components/BlockContent';
import { BemBox } from '../../components/Box';
import { BemButton } from '../../components/Button';
import { BemErrorBox } from '../../components/ErrorBox';
import { BemFlex } from '../../components/Flex';
import { BemGrid } from '../../components/Grid';
import { BemHeadline } from '../../components/Headline';
import { BemIcon } from '../../components/Icon';
import {
  BemInputField,
  BemEmailField,
  BemPostCodeField,
  BemRadioField,
  BemSelectField,
  BemTaxIdField,
} from '../../components/InputFields';
import { BemOverlay } from '../../components/Overlay';
import { BemPopover } from '../../components/Popover';
import { BemProductPrice } from '../../components/ProductPrice';
import { BemSection } from '../../components/Section';
import { BemSpinner } from '../../components/Spinner/BemSpinner';
import { BemText } from '../../components/Text';

const sharedHolderHeaderStyle = css`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 34px;
`;

const StyledDeliveryAddressHolderHeader = styled.div`
  ${sharedHolderHeaderStyle}
`;

interface IPropsDeliveryAddressHolder extends IPropsUiLibBase {
  isVisible: boolean;
  onOpen: () => void;
  onCancel: () => void;
}

interface IProductNames {
  [k: string]: string;
}

const DeliveryAddressHolder = ({
  isVisible = false,
  onOpen = () => undefined,
  onCancel = () => undefined,
  T = () => '',
  children,
}: IPropsDeliveryAddressHolder) => {
  return (
    <div>
      <StyledDeliveryAddressHolderHeader>
        {isVisible ? (
          <>
            <BemText isBold rawSx={{ mb: '16px' }}>
              {T('fieldset_headline__deliveryAddress_data')}
            </BemText>
            <BemButton
              variant="link"
              onClick={onCancel}
              testId="button__cancel"
            >
              {T('button__cancel__label')}
            </BemButton>
          </>
        ) : (
          <BemButton
            variant="link"
            onClick={onOpen}
            testId="button__addDeliveryAddress"
          >
            {T('button__addDeliveryAddress__label')}
          </BemButton>
        )}
      </StyledDeliveryAddressHolderHeader>
      {isVisible && children}
    </div>
  );
};

const FIRST_NAME_MAX_LENGTH = 20;
const MIDDLE_NAME_MAX_LENGTH = 20;
const LAST_NAME_MAX_LENGTH = 20;
const TOTAL_NAME_MAX_LENGTH = 50;

const PHONE_NUMBER_MAX_LENGTH = 16;
const EMAIL_MAX_LENGTH = 80;
const ORGANIZATION_MAX_LENGTH = 50;
const TAX_ID_MAX_LENGTH = 20;

const STREET_MAX_LENGTH = 50;
const STREET_NUMBER_MAX_LENGTH = 10;
const STREET_ADDITIONAL_MAX_LENGTH = 20;
const CITY_MAX_LENGTH = 30;
const POSTAL_CODE_MAX_LENGTH = 20;
const TOTAL_ADDRESS_MAX_LENGTH = 100;

const DISTRIBUTOR_NUMBER_MAX_LENGTH = 20;
const DISTRIBUTOR_NAME_MAX_LENGTH = 50;

export const BemModuleOrderABemerTrial = ({
  title,
  legalCopy,
  formError,
  products,
  T = () => '',
  partnerFinderData,
  countriesToLanguages = [],
  locale = defaultLocale,
  onSubmit = () => undefined,
}: IPropsBemModuleOrderABemerTrial): JSX.Element => {
  const formMethods = useForm({
    mode: 'onBlur',
  });
  const { isSubmitting } = formMethods.formState;
  const formProps = { formMethods, T };
  const possibleBirthdateValues = getPossibleBirthDateValues(locale);
  const pleaseChooseOption = {
    id: -1,
    value: '',
    label: T('option__pleaseChoose'),
  };

  const [isPartnerNameFieldVisible, setIsPartnerNameFieldVisible] = useState(
    false
  );

  useEffect(() => {
    formMethods.setValue(
      'distributorNo',
      partnerFinderData?.partnerData?.customerNo
    );
    formMethods.setValue(
      'distributorName',
      partnerFinderData?.partnerData?.name
    );

    setIsPartnerNameFieldVisible(!!partnerFinderData?.partnerData?.name);
  }, [partnerFinderData]);

  const productList = products
    .filter((product) => product.productValue !== 'fee')
    .map((product) => {
      const productNameMapping: IProductNames = {
        'go-set': 'set-go',
        'classic-set': 'set-classic',
        'pro-set': 'set-pro',
        'evo-set-premium': 'premium-set-evo',
      };

      return {
        id: product.productValue,
        label: T(`option__${productNameMapping[product.productValue]}`),
        value: product.productValue,
        price: product.productPrice,
        isSelected: false,
      };
    });

  const sortedProductsByPrice = productList
    .sort((productA, productB) => {
      if (productA.price < productB.price) {
        return -1;
      }
      if (productA.price > productB.price) {
        return 1;
      }
      return 0;
    })
    .reverse();

  const selectedProduct = products.filter(
    ({ productValue }) =>
      productValue === formMethods.watch('set', sortedProductsByPrice[0].value)
  )[0];
  productList[productList.indexOf(sortedProductsByPrice[0])].isSelected = true;

  const [isDeliveryAddressVisible, setIsDeliveryAddressVisible] = useState(
    false
  );

  const handleDeliveryAddressOpen = () => setIsDeliveryAddressVisible(true);
  const handleDeliveryAddressCancel = () => {
    setIsDeliveryAddressVisible(false);
    [
      'firstNameDeliveryAddress',
      'middleNameDeliveryAddress',
      'lastNameDeliveryAddress',
      'organizationDeliveryAddress',
      'streetDeliveryAddress',
      'streetNumberDeliveryAddress',
      'streetAdditionalDeliveryAddress',
      'cityDeliveryAddress',
      'federalStateDeliveryAddress',
      'postalCodeDeliveryAddress',
      'countryDeliveryAddress',
    ].forEach((fieldName: string) => formMethods.setValue(fieldName, null));
  };

  const maxLengthErrorMsg = T('error__errorBox__max_letters');
  const maxTotalNameLengthErrorMsg = T(
    'error__errorBox__max_50_names_combined'
  );

  /*
   * Validation handlers
   * ====================================================================================
   * For the combined name, only the lastName field will display the error message.
   * For the combined address, only the city field will display the error message.
   *
   * I the case when a user enters the form backwards, the lastName (or city) field will
   * be valid when the user triggers the blur and with that the validation of the field.
   * The user than is able to enter long names in the other fields and generate a too long name
   * and the user will see no error since when leaving the lastName field,
   * the combined value was valid.
   *
   * Since react-hook-form revalidates all fields before a submit, the error will be displayed after
   * the user has pressed the submit button in that case.
   */

  // The fieldNameSuffix is used when the delivery address names are validated.
  const isCombinedNameLengthValid = (
    lastName: string,
    fieldNameSuffix = ''
  ): boolean | string => {
    // Mapping only for readability.
    const v = formMethods.getValues;

    const firstNameLength = `${v(`firstName${fieldNameSuffix}`)} `.length;
    const middleName = v(`middleName${fieldNameSuffix}`);
    const middleNameLength = (middleName ? `${middleName} ` : '').length;
    const lastNameLength = lastName.length;

    return (
      firstNameLength + middleNameLength + lastNameLength <=
        TOTAL_NAME_MAX_LENGTH || maxTotalNameLengthErrorMsg
    );
  };

  const isCombinedDeliveryNameLengthValid = (
    lastName: string
  ): boolean | string => {
    return isCombinedNameLengthValid(lastName, 'DeliveryAddress');
  };

  // The fieldNameSuffix is used when the delivery address is validated.
  const isCombinedAddressLengthValid = (
    city: string,
    fieldNameSuffix = ''
  ): boolean | string => {
    // Mapping only for readability.
    const v = formMethods.getValues;

    const streetNameLength = `${v(`street${fieldNameSuffix}`)} `.length;
    const streetNumberLength = `${v(`streetNumber${fieldNameSuffix}`)} `.length;
    const streetAdditional = v(`streetAdditional${fieldNameSuffix}`);
    const streetAdditionalLength = (streetAdditional
      ? `${streetAdditional} `
      : ''
    ).length;
    const cityLength = `${city} `.length;
    const postalCodeLength = v(`postalCode${fieldNameSuffix}`).length;

    return (
      streetNameLength +
        streetNumberLength +
        streetAdditionalLength +
        cityLength +
        postalCodeLength <=
        TOTAL_ADDRESS_MAX_LENGTH || maxLengthErrorMsg
    );
  };

  const isCombinedDeliveryAddressLengthValid = (
    city: string
  ): boolean | string => {
    return isCombinedAddressLengthValid(city, 'DeliveryAddress');
  };

  const dateFormat = dateFormatByCountry[locale.countryCode].split('') as (
    | 'D'
    | 'M'
    | 'Y'
  )[];

  enum DateComponents {
    'DAY' = 'D',
    'MONTH' = 'M',
    'YEAR' = 'Y',
  }

  const birthdayPickerDateComponents: {
    [key in DateComponents]: JSX.Element;
  } = {
    D: (
      <BemSelectField
        fieldName="birthDateDay"
        label={T('field__birthDateDay__label')}
        placeholder={T('field__birthDateDay__placeholder', false)}
        options={[pleaseChooseOption, ...possibleBirthdateValues.days]}
        validationRules={{ required: T('error__required') }}
        {...formProps}
      />
    ),

    M: (
      <BemSelectField
        fieldName="birthDateMonth"
        label={T('field__birthDateMonth__label')}
        placeholder={T('field__birthDateMonth__placeholder', false)}
        options={[pleaseChooseOption, ...possibleBirthdateValues.months]}
        validationRules={{ required: T('error__required') }}
        {...formProps}
      />
    ),

    Y: (
      <BemSelectField
        fieldName="birthDateYear"
        label={T('field__birthDateYear__label')}
        placeholder={T('field__birthDateYear__placeholder', false)}
        options={[pleaseChooseOption, ...possibleBirthdateValues.years]}
        validationRules={{ required: T('error__required') }}
        {...formProps}
      />
    ),
  };

  const getBirthdayComponent = (componentName: 'D' | 'M' | 'Y') => {
    return birthdayPickerDateComponents[componentName];
  };

  return (
    <BemSection rawSx={{ pt: '100px' }}>
      {isSubmitting && (
        <BemOverlay>
          <BemSpinner />
        </BemOverlay>
      )}
      <BemBox
        as="form"
        onSubmit={formMethods.handleSubmit(onSubmit)}
        rawSx={{ width: '100%', maxWidth: '645px' }}
      >
        <BemHeadline rank="h1" rawSx={{ mb: '28px' }}>
          {title}
        </BemHeadline>
        {formError && (
          <BemErrorBox
            headline={formError.headline}
            message={formError.message}
          />
        )}
        <BemText isBold rawSx={{ mb: '16px' }}>
          {T('fieldset_headline__choose_product')}
        </BemText>
        <BemRadioField
          fieldName="set"
          options={productList}
          validationRules={{ required: T('error__required') }}
          {...formProps}
        />

        <BemProductPrice
          selectedProduct={selectedProduct}
          products={products}
          T={T}
          locale={locale}
        />
        <BemText isBold rawSx={{ mb: '16px', mt: '40px' }}>
          {T('fieldset_headline__personal_data')}
        </BemText>
        <BemInputField
          fieldName="firstName"
          label={T('field__firstName__label')}
          placeholder={T('field__firstName__placeholder', false)}
          validationRules={{
            required: T('error__required'),
            maxLength: {
              value: FIRST_NAME_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
        />
        <BemInputField
          fieldName="middleName"
          label={T('field__middleName__label')}
          placeholder={T('field__middleName__placeholder', false)}
          validationRules={{
            maxLength: {
              value: MIDDLE_NAME_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
        />
        <BemInputField
          fieldName="lastName"
          label={T('field__lastName__label')}
          placeholder={T('field__lastName__placeholder', false)}
          validationRules={{
            required: T('error__required'),
            maxLength: {
              value: LAST_NAME_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
            validate: {
              combinedNameLength: isCombinedNameLengthValid,
            },
          }}
          {...formProps}
        />
        <BemText isBold rawSx={{ mb: '16px' }}>
          {T('field__birthDate__headline')}
        </BemText>
        <BemGrid gap={2} columns="1fr 1fr 1fr">
          {dateFormat.map((dateComponent) => (
            <React.Fragment key={`field__birthDatePicker_${dateComponent}`}>
              {getBirthdayComponent(dateComponent)}
            </React.Fragment>
          ))}
        </BemGrid>
        <BemText isBold rawSx={{ mb: '16px' }}>
          {T('field__gender__headline')}
        </BemText>
        <BemRadioField
          fieldName="gender"
          options={[
            {
              id: 'female',
              label: T('option__female'),
              value: 'F',
              isSelected: true,
            },
            {
              id: 'male',
              label: T('option__male'),
              value: 'M',
            },
            {
              id: 'other',
              label: T('option__other'),
              value: 'O',
            },
          ]}
          validationRules={{ required: T('error__required') }}
          {...formProps}
        />
        <BemInputField
          fieldName="phoneNo"
          label={T('field__phoneNo__label')}
          placeholder={T('field__phoneNo__placeholder', false)}
          validationRules={{
            required: T('error__required'),
            maxLength: {
              value: PHONE_NUMBER_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
          suffix={
            <BemPopover
              placement="bottomRight"
              content={T('field__phoneNo__popover')}
              rawSx={{ marginRight: '-20px', width: 300 }}
            >
              <BemIcon iconName="infoCircle" />
            </BemPopover>
          }
        />

        <BemEmailField
          fieldName="email"
          label={T('field__email__label')}
          suffix={<BemSpinner size="small" />}
          placeholder={T('field__email__placeholder', false)}
          validationRules={{
            required: T('error__required'),
            pattern: {
              value: /^\S+@\S+$/i,
              message: T('field__email__error__pattern'),
            },
            maxLength: {
              value: EMAIL_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          checkBackend
          {...formProps}
        />

        <BemInputField
          fieldName="organization"
          label={T('field__organization__label')}
          placeholder={T('field__organization__placeholder', false)}
          validationRules={{
            maxLength: {
              value: ORGANIZATION_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
        />

        {locale.countryCode !== 'US' && (
          <BemTaxIdField
            fieldName="taxId"
            label={T('field__taxId__label')}
            placeholder={T('field__taxId__placeholder', false)}
            validationRules={{
              maxLength: {
                value: TAX_ID_MAX_LENGTH,
                message: maxLengthErrorMsg,
              },
            }}
            locale={locale}
            checkBackend={checkTaxIdForCountry[locale.countryCode]}
            {...formProps}
          />
        )}

        <BemText isBold rawSx={{ mb: '16px', mt: '40px' }}>
          {T('fieldset_headline__address_data')}
        </BemText>
        <BemGrid gap={2} columns="1fr 1fr">
          <BemBox>
            <BemInputField
              fieldName="street"
              label={T('field__street__label')}
              placeholder={T('field__street__placeholder', false)}
              validationRules={{
                required: T('error__required'),
                maxLength: {
                  value: STREET_MAX_LENGTH,
                  message: maxLengthErrorMsg,
                },
              }}
              {...formProps}
            />
          </BemBox>
          <BemBox>
            <BemInputField
              fieldName="streetNumber"
              label={T('field__streetNumber__label')}
              placeholder={T('field__streetNumber__placeholder', false)}
              validationRules={{
                required: T('error__required'),
                maxLength: {
                  value: STREET_NUMBER_MAX_LENGTH,
                  message: maxLengthErrorMsg,
                },
              }}
              {...formProps}
            />
          </BemBox>
        </BemGrid>
        <BemInputField
          fieldName="streetAdditional"
          label={T('field__streetAdditional__label')}
          placeholder={T('field__streetAdditional__placeholder', false)}
          validationRules={{
            maxLength: {
              value: STREET_ADDITIONAL_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
        />
        <BemInputField
          fieldName="city"
          label={T('field__city__label')}
          placeholder={T('field__city__placeholder', false)}
          validationRules={{
            required: T('error__required'),
            maxLength: {
              value: CITY_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
            validate: {
              combinedAddressLength: isCombinedAddressLengthValid,
            },
          }}
          {...formProps}
        />
        <BemGrid gap={2} columns="1fr 1fr">
          <BemBox>
            <BemSelectField
              fieldName="federalState"
              label={T('field__federalState__label')}
              placeholder={T('field__federalState__placeholder', false)}
              options={[pleaseChooseOption, ...getFederalStateValues(locale)]}
              validationRules={{
                required: T('error__required'),
              }}
              {...formProps}
            />
          </BemBox>
          <BemBox>
            <BemPostCodeField
              fieldName="postalCode"
              label={T('field__postalCode__label')}
              locale={locale}
              placeholder={T('field__postalCode__placeholder', false)}
              validationRules={{
                required: T('error__required'),
                maxLength: {
                  value: POSTAL_CODE_MAX_LENGTH,
                  message: maxLengthErrorMsg,
                },
              }}
              checkBackend={locale.countryCode === 'US'}
              {...formProps}
            />
          </BemBox>
        </BemGrid>
        <BemSelectField
          fieldName="country"
          label={T('field__country__label')}
          placeholder={T('field__country__placeholder', false)}
          validationRules={{ required: T('error__required') }}
          value={locale.countryCode}
          options={[
            pleaseChooseOption,
            ...countriesToLanguages.map(
              ({ countryCode, countryName }: ICountriesToLanguages) => ({
                value: countryCode,
                label: countryName,
              })
            ),
          ]}
          isDisabled
          {...formProps}
        />
        <DeliveryAddressHolder
          isVisible={isDeliveryAddressVisible}
          T={T}
          onOpen={handleDeliveryAddressOpen}
          onCancel={handleDeliveryAddressCancel}
        >
          <BemInputField
            fieldName="firstNameDeliveryAddress"
            label={T('field__firstName__label')}
            placeholder={T('field__firstName__placeholder', false)}
            validationRules={{
              required: T('error__required'),
              maxLength: {
                value: FIRST_NAME_MAX_LENGTH,
                message: maxLengthErrorMsg,
              },
            }}
            {...formProps}
          />
          <BemInputField
            fieldName="middleNameDeliveryAddress"
            label={T('field__middleName__label')}
            placeholder={T('field__middleName__placeholder', false)}
            validationRules={{
              maxLength: {
                value: MIDDLE_NAME_MAX_LENGTH,
                message: maxLengthErrorMsg,
              },
            }}
            {...formProps}
          />
          <BemInputField
            fieldName="lastNameDeliveryAddress"
            label={T('field__lastName__label')}
            placeholder={T('field__lastName__placeholder', false)}
            validationRules={{
              required: T('error__required'),
              maxLength: {
                value: LAST_NAME_MAX_LENGTH,
                message: maxLengthErrorMsg,
              },
              validate: {
                combinedNameLength: isCombinedDeliveryNameLengthValid,
              },
            }}
            {...formProps}
          />
          <BemInputField
            fieldName="organizationDeliveryAddress"
            label={T('field__organization__label')}
            placeholder={T('field__organization__placeholder', false)}
            validationRules={{
              maxLength: {
                value: ORGANIZATION_MAX_LENGTH,
                message: maxLengthErrorMsg,
              },
            }}
            {...formProps}
          />
          <BemGrid gap={2} columns="1fr 1fr">
            <BemBox>
              <BemInputField
                fieldName="streetDeliveryAddress"
                label={T('field__street__label')}
                placeholder={T('field__street__placeholder', false)}
                validationRules={
                  isDeliveryAddressVisible
                    ? {
                        required: T('error__required'),
                        maxLength: {
                          value: STREET_MAX_LENGTH,
                          message: maxLengthErrorMsg,
                        },
                      }
                    : {}
                }
                {...formProps}
              />
            </BemBox>
            <BemBox>
              <BemInputField
                fieldName="streetNumberDeliveryAddress"
                label={T('field__streetNumber__label')}
                placeholder={T('field__streetNumber__placeholder', false)}
                validationRules={
                  isDeliveryAddressVisible
                    ? {
                        required: T('error__required'),
                        maxLength: {
                          value: STREET_NUMBER_MAX_LENGTH,
                          message: maxLengthErrorMsg,
                        },
                      }
                    : {}
                }
                {...formProps}
              />
            </BemBox>
          </BemGrid>
          <BemInputField
            fieldName="streetAdditionalDeliveryAddress"
            label={T('field__streetAdditional__label')}
            placeholder={T('field__streetAdditional__placeholder', false)}
            validationRules={{
              maxLength: {
                value: STREET_ADDITIONAL_MAX_LENGTH,
                message: maxLengthErrorMsg,
              },
            }}
            {...formProps}
          />
          <BemInputField
            fieldName="cityDeliveryAddress"
            label={T('field__city__label')}
            placeholder={T('field__city__placeholder', false)}
            validationRules={
              isDeliveryAddressVisible
                ? {
                    required: T('error__required'),
                    maxLength: {
                      value: CITY_MAX_LENGTH,
                      message: maxLengthErrorMsg,
                    },
                    validate: {
                      combinedAddressLength: isCombinedDeliveryAddressLengthValid,
                    },
                  }
                : {}
            }
            {...formProps}
          />
          <BemGrid gap={2} columns="1fr 1fr">
            <BemBox>
              <BemSelectField
                fieldName="federalStateDeliveryAddress"
                label={T('field__federalState__label')}
                placeholder={T('field__federalState__placeholder', false)}
                options={[pleaseChooseOption, ...getFederalStateValues(locale)]}
                validationRules={
                  isDeliveryAddressVisible
                    ? {
                        required: T('error__required'),
                      }
                    : {}
                }
                {...formProps}
              />
            </BemBox>
            <BemBox>
              <BemPostCodeField
                fieldName="postalCodeDeliveryAddress"
                label={T('field__postalCode__label')}
                locale={locale}
                placeholder={T('field__postalCode__placeholder', false)}
                validationRules={
                  isDeliveryAddressVisible
                    ? {
                        required: T('error__required'),
                        maxLength: {
                          value: POSTAL_CODE_MAX_LENGTH,
                          message: maxLengthErrorMsg,
                        },
                      }
                    : {}
                }
                checkBackend={
                  isDeliveryAddressVisible && locale.countryCode === 'US'
                }
                {...formProps}
              />
            </BemBox>
          </BemGrid>
          <BemSelectField
            fieldName="countryDeliveryAddress"
            label={T('field__country__label')}
            placeholder={T('field__country__placeholder', false)}
            validationRules={
              isDeliveryAddressVisible
                ? {
                    required: T('error__required'),
                  }
                : {}
            }
            value={locale.countryCode}
            options={[
              pleaseChooseOption,
              ...countriesToLanguages.map(
                ({ countryCode, countryName }: ICountriesToLanguages) => ({
                  value: countryCode,
                  label: countryName,
                })
              ),
            ]}
            isDisabled
            {...formProps}
          />
        </DeliveryAddressHolder>
        <BemText isBold rawSx={{ mb: '16px', mt: '40px' }}>
          {T('fieldset_headline__bemer_partner')}
        </BemText>
        <BemInputField
          fieldName="distributorNo"
          isVisible={!isPartnerNameFieldVisible}
          label={T('field__distributorNo__label')}
          placeholder={T('field__distributorNo__placeholder', false)}
          validationRules={{
            required: T('error__required'),
            pattern: {
              // [BEM-5225] D00110 is "Landesagentur Skandinavien"
              value: /((?!^CA99999|US99999|CH99999$))[A-Z]{2}\d{5}$|C\d{7}$|C\d-\d{6}|D00110$/,
              message: T('field__distributorNo__error__pattern'),
            },
            maxLength: {
              value: DISTRIBUTOR_NUMBER_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
        />
        <BemInputField
          fieldName="distributorName"
          isVisible={isPartnerNameFieldVisible}
          label={T('field__distributorName__label')}
          placeholder={T('field__distributorName__placeholder', false)}
          isDisabled
          isBold
          validationRules={{
            required: T('error__required'),
            maxLength: {
              value: DISTRIBUTOR_NAME_MAX_LENGTH,
              message: maxLengthErrorMsg,
            },
          }}
          {...formProps}
        />
        <BemFlex
          rawSx={{
            mt: '40px',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <BemBlockContent
            blocks={legalCopy}
            rawBemTextSx={{
              fontSize: '16px',
            }}
          />
          <BemButton
            type="submit"
            rawSx={{ minWidth: '40%', ml: '16px' }}
            isDisabled={isSubmitting}
            testId="button__submit"
          >
            {T('button__submit__label')}
          </BemButton>
        </BemFlex>
      </BemBox>
    </BemSection>
  );
};
